import React, { useEffect, useMemo } from 'react';
import { PlanSelectorContext } from '../../contexts/planSelectorContext';
import {
  planSelectorInitialState,
  planSelectorReducer
} from '../../reducers/planSelectorReducer';
import { useAsyncReducer } from '../../hooks';
import { Plan } from '../../../../common/types/plan';
import { GenericErrorHandler } from '../GenericErrorHandler';
import { PlanSelectorContent } from './planSelectorContent';
import { fetchPlansAction, setAssetsProviderAction } from '../../actions';
import { setFallbackLocale } from '../../../../common/lib/localeHelpers';
import { trackClickEvent } from '../../../../common/types/planSelectorState';

export type PlanSelectorProps = {
  /**
   * savedPlanGUID GUID for previous selection
   */
  savedPlanGUID?: string;
  /**
   * country used in combination with language param for fetching translations
   */
  country: string;
  /**
   * language used in combination with country param for fetching translations
   */
  language: string;
  /**
   * defaultPlanGUIDInk used for popular inkplans
   */
  defaultPlanGUIDInk?: string;
  /**
   * defaultPlanGUIDToner used for recommended toner plans
   */
  defaultPlanGUIDToner?: string;
  /**
   * getPlans async callback function that returns list of available plans
   */
  getPlans: () => Promise<Plan[]>;
  /**
   * onPlanChange Triggered when user selects save plan button
   */
  onPlanChange: (selectedPlanID: number) => Promise<void>;
  /**
   * onCancel In case of error, the onCancel callback can be used for going back to previous state
   */
  trackClickEvent: trackClickEvent;
  /**
   * trackClickEvent linkId and linkPlacement to the tracker for analitycs callback events
   */
  onCancel: () => void;
  /**
   * program param at component parameters level, so host can decide which theme to render.
   */
  program: string;
  /**
   * showPlanProgramTabs used for show tabs with options of Ink and Toner Plans
   */
  showPlanProgramTabs: boolean;
  /**
   * showPriceIncreaseInfo used for show information about changes in the Ink plans price
   */
  showPriceIncreaseInfo?: boolean;
};

export const PlanSelector = (props: PlanSelectorProps) => {
  const { language, country } = useMemo(
    () => setFallbackLocale(props.language, props.country),
    [props.language, props.country]
  );

  const [planSelectorState, dispatch] = useAsyncReducer(
    planSelectorReducer,
    planSelectorInitialState({ ...props, language, country })
  );

  useEffect(() => {
    (async () => {
      await dispatch(setAssetsProviderAction(language, country));
    })();
  }, [language, country, dispatch]);

  const { plans } = planSelectorState;

  useEffect(() => {
    if (!plans) {
      dispatch(fetchPlansAction());
    }
  }, []);

  return (
    <PlanSelectorContext.Provider value={{ planSelectorState, dispatch }}>
      <GenericErrorHandler />
      {plans && <PlanSelectorContent />}
    </PlanSelectorContext.Provider>
  );
};
