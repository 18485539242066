/* eslint-disable @typescript-eslint/no-unused-vars */
import { YearlyPlanSelectorState } from '../types/yearlyPlanSelectorState';
import { YearlyPlanSelectorProps } from '../types/yearlyPlanSelectorProps';
import { YearlyPlanSelectorAction } from '../types/yearlyPlanSelectorAction';
import { DashboardPlan } from '../../common/types/dashboardPlan';

const defaultPlan = {
  id: 0,
  guid: '',
  price: '',
  pages: 0,
  overageBlockPrice: '',
  overageBlockSize: 0,
  program: '',
  rollover: 0,
  frequency: '',
  yearlyPrice: ''
};

const findIdByCurrentPlanGUID = (
  plans: DashboardPlan[],
  currentPlanGUID: string
) => {
  const index = plans.findIndex((plan) => plan.guid === currentPlanGUID);
  const plan = index !== -1 ? plans[index] : defaultPlan;
  return { plan, index };
};

export const YearlyPlanSelectorInitialState = ({
  plans,
  currentPlanGUID,
  isStandAloneMode,
  trackClickEvent = (linkId: string, linkPlacement: string): null => null,
  onARNConfirmClick = () => null,
  onCloseModal = () => null
}: YearlyPlanSelectorProps): YearlyPlanSelectorState => {
  const currentPlan = findIdByCurrentPlanGUID(plans, currentPlanGUID);

  return {
    plans,
    currentPlan: {
      id: currentPlan.plan.id,
      index: currentPlan.index,
      guid: currentPlanGUID,
      plan: currentPlan.plan
    },
    trackClickEvent,
    isStandAloneMode,
    onARNConfirmClick,
    onCloseModal
  };
};

export const yearlyPlanSelectorReducer = (
  state: YearlyPlanSelectorState,
  action: YearlyPlanSelectorAction
) => {
  const newState = state;
  return newState;
};
