import AssetsProviderFactory from '../../assets/AssetsProviderFactory';
import {
  COMPARE_YEARLY_MONTHLY_STEP,
  YEARLY_PLAN_SELECTOR_STEP
} from '../constants';
import { JarvisPlansAction } from '../types/jarvisPlansAction';
import { JarvisPlansProps } from '../types/jarvisPlansProps';
import { JarvisPlansState } from '../types/jarvisPlansState';

export const jarvisPlansInitialState = ({
  country,
  language,
  isStandAloneMode
}: JarvisPlansProps): JarvisPlansState => {
  const initialStep = isStandAloneMode
    ? YEARLY_PLAN_SELECTOR_STEP
    : COMPARE_YEARLY_MONTHLY_STEP;
  return {
    getText: AssetsProviderFactory.create(language, country).getText.bind(
      AssetsProviderFactory.create(language, country)
    ),
    initialStep,
    currentStep: initialStep
  };
};

export const jarvisPlansReducer = (
  state: JarvisPlansState,
  action: JarvisPlansAction
) => {
  let newState = state;

  switch (action.type) {
    case 'SET_ASSETS_PROVIDER':
      if (action.language && action.country) {
        newState = {
          ...state,
          getText: AssetsProviderFactory.create(
            action.language,
            action.country
          ).getText.bind(
            AssetsProviderFactory.create(action.language, action.country)
          )
        };
      }
      break;
    case 'SET_CURRENT_STEP':
      if (action.currentStep) {
        newState = {
          ...state,
          currentStep: action.currentStep
        };
      }
      break;
  }
  return newState;
};
