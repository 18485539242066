import React, { useState } from 'react';

import {
  DisclaimerContent,
  DisclaimerItem,
  DisclaimerTitle,
  VeneerAccordion
} from './styles';
import { useGetText } from '../../../common/hooks/useGetText';

export const CompareYearlyPlanDisclaimer: React.FC = () => {
  const getText = useGetText();

  const Header: React.FC = () => {
    return (
      <DisclaimerTitle>
        {getText('plans_selector.yearly.modals.disclaimers')}
      </DisclaimerTitle>
    );
  };

  const Content: React.FC = () => {
    return (
      <DisclaimerContent>
        <DisclaimerItem>
          {getText('plans_selector.yearly.modals.disclaimers_based_on_yearly')}
        </DisclaimerItem>
        <DisclaimerItem>
          {getText(
            'plans_selector.yearly.modals.disclaimers_yearly_plans_automatically'
          )}
        </DisclaimerItem>
        <DisclaimerItem>
          {getText(
            'plans_selector.yearly.modals.disclaimers_refund_prorated_based'
          )}
        </DisclaimerItem>
        <DisclaimerItem
          dangerouslySetInnerHTML={{
            __html: getText(
              'plans_selector.yearly.modals.disclaimers_recycling'
            )
          }}
        />
      </DisclaimerContent>
    );
  };

  const [data, setData] = useState([
    {
      header: { centralArea: <Header /> },
      content: <Content />,
      expanded: false
    }
  ]);

  const handleExpand = () => {
    const updatedData = [...data];
    updatedData[0].expanded = true;
    setData(updatedData);
  };
  const handleCollapse = () => {
    const updatedData = [...data];
    updatedData[0].expanded = false;
    setData(updatedData);
  };

  return (
    <VeneerAccordion
      background="filled"
      items={data}
      onCollapse={handleCollapse}
      onExpand={handleExpand}
    />
  );
};
