import React from 'react';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';
import { PlanCard } from '../PlanCard';
import { PlanCardYearly } from '../PlanCardYearly';
import { PLAN_PROGRAMMES } from '../../../common/constants';
import { PlanCardContainer } from './styles';
import { PaperCard } from '../PaperCard';
import { YearlyCard } from '../YearlyCard';

export const DashboardPlansContent: React.FC = () => {
  const {
    isYearlyPlanSelected,
    selectedPlan: { plan },
    plans,
    program,
    showPaperCard,
    showYearlyCard
  } = useDashboardPlansState();

  if (isYearlyPlanSelected) {
    return <PlanCardYearly index={2} plan={plan} />;
  }

  return (
    <PlanCardContainer>
      {showYearlyCard && <YearlyCard />}
      {plans.map((plan, index) => {
        return <PlanCard key={index} index={index} plan={plan} />;
      })}
      {showPaperCard && program == PLAN_PROGRAMMES.INSTANT_INK && <PaperCard />}
    </PlanCardContainer>
  );
};
