import React from 'react';
import { AutomaticRenewalNotice } from '@jarvis/react-instant-ink-automatic-renewal-notice';
import { useGetText } from '../../../../../common/hooks/useGetText';
import { TOSCheckbox, ButtonContainer, EnrollButton } from './styles';
import { useYearlyPlanSelectorState } from '../../../../../YearlyPlanSelector/hooks/useYearlyPlanSelectorState';

export const JarvisARN: React.FC = () => {
  const { onARNConfirmClick, onCloseModal } = useYearlyPlanSelectorState();

  const handleEnrollButtonClick = () => {
    // This should be the current selected plan after integration is done
    const selectedPlanMock = {
      id: 0,
      guid: '',
      price: '',
      pages: 0,
      overageBlockPrice: '',
      overageBlockSize: 0,
      rollover: 0,
      frequency: '',
      addons: [] as [],
      yearlyPrice: ''
    };
    onARNConfirmClick(selectedPlanMock);
    onCloseModal();
  };

  const [acceptedTermsOfService, setAcceptedTermsOfService] =
    React.useState(false);
  const getText = useGetText();
  return (
    <>
      <AutomaticRenewalNotice
        language="en"
        country="US"
        hasPrepaidBalance
        prepaidBalanceFormatted={'$10'}
        totalFreeMonths={10}
        waiverPages={10}
        cancelUrl={'cancelurl.com'}
        dashboardUrl={'dashbord_url.com'}
        trackClickEvent={(linkId, linkPlacement) =>
          console.log('Tracking Click Event: ', linkId, linkPlacement)
        }
        isV3
      />
      <TOSCheckbox
        id="terms-of-service"
        data-testid="terms-agreement"
        data-linkid="ii_plans"
        data-analyticsid="ii_plans"
        name="termsOfService"
        checked={acceptedTermsOfService}
        onChange={() => setAcceptedTermsOfService((prev) => !prev)}
        label={
          <p
            dangerouslySetInnerHTML={{
              __html: getText(
                'plans_selector.yearly_confirmation.modals.i_have_read_and_agree',
                { hpTermsOfService: '/terms-of-service' }
              )
            }}
          />
        }
      />
      <ButtonContainer>
        <EnrollButton
          appearance={'secondary'}
          onClick={onCloseModal}
          data-testid="cancel-terms-agreement-button"
          data-linkid="ii_plans"
          data-analyticsid="ii_plans"
        >
          {getText('plans_selector.cancel')}
        </EnrollButton>
        <EnrollButton
          disabled={!acceptedTermsOfService}
          onClick={handleEnrollButtonClick}
          data-testid="confirm-terms-agreement-button"
          data-linkid="ii_plans"
          data-analyticsid="ii_plans"
        >
          {getText('plans_selector.confirm_plan')}
        </EnrollButton>
      </ButtonContainer>
    </>
  );
};
