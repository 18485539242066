import React, { useEffect, useMemo, useReducer } from 'react';
import { YearlyPlanSelectorContext } from './context/yearlyPlanSelectorContext';
import {
  YearlyPlanSelectorInitialState,
  yearlyPlanSelectorReducer
} from './reducers/yearlyPlanSelectorReducer';
import { setAssetsProviderAction } from '../common/actions/setAssetsProviderAction';
import { YearlyPlanSelectorContent } from './components/YearlyPlanSelectorContent';
import { YearlyPlanSelectorProps } from './types/yearlyPlanSelectorProps';
import {
  jarvisPlansReducer,
  jarvisPlansInitialState
} from '../common/reducers/jarvisPlansReducer';
import { JarvisPlansContext } from '../common/context/jarvisPlanContext';

export const YearlyPlanSelector: React.FC<YearlyPlanSelectorProps> = (
  props
) => {
  const [jarvisPlansState, jarvisDispatch] = useReducer(
    jarvisPlansReducer,
    jarvisPlansInitialState(props)
  );

  const [yearlyPlanSelectorState, dispatch] = useReducer(
    yearlyPlanSelectorReducer,
    YearlyPlanSelectorInitialState(props)
  );

  useEffect(() => {
    jarvisDispatch(setAssetsProviderAction(props.language, props.country));
  }, [props.language, props.country]);

  const contextValue = useMemo(
    () => ({ yearlyPlanSelectorState, dispatch }),
    [yearlyPlanSelectorState, dispatch]
  );

  const jarvisContextValue = useMemo(
    () => ({ jarvisPlansState, dispatch: jarvisDispatch }),
    [jarvisPlansState, jarvisDispatch]
  );

  if (!props.isStandAloneMode) {
    return (
      <YearlyPlanSelectorContext.Provider value={contextValue}>
        <YearlyPlanSelectorContent
          data-analyticsid="ii_plans_selector-content"
          creditCard={props.creditCard}
          onChangePaymentMethod={props.onChangePaymentMethod}
          onSubmitCVV={props.onSubmitCVV}
          cvvStatus={props.cvvStatus}
        />
      </YearlyPlanSelectorContext.Provider>
    );
  }

  return (
    <JarvisPlansContext.Provider value={jarvisContextValue}>
      <YearlyPlanSelectorContext.Provider value={contextValue}>
        <YearlyPlanSelectorContent
          data-analyticsid="ii_plans_selector-content"
          creditCard={props.creditCard}
          onChangePaymentMethod={props.onChangePaymentMethod}
          onSubmitCVV={props.onSubmitCVV}
          cvvStatus={props.cvvStatus}
        />
      </YearlyPlanSelectorContext.Provider>
    </JarvisPlansContext.Provider>
  );
};

export default YearlyPlanSelector;
