import React from 'react';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';
import IconCheckmarkCircle from '@veneer/core/dist/scripts/icons/icon_checkmark_circle';
import {
  HeaderContainer,
  Table,
  PlanLabel,
  LabelText,
  TableRow,
  YearlyCell,
  TitleCell,
  MonthlyCell,
  Divider
} from './styles';
import { useGetText } from '../../../common/hooks/useGetText';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';

export const YearlyPlanComparisonTable: React.FC = () => {
  const getText = useGetText();
  const isTablet = useMediaQuery(
    `(max-width: ${breakpointsHelper.medium_max_991px})`
  );

  const createTableRow = (titleKey, monthlyContent, yearlyContent) => ({
    title: getText(titleKey),
    monthly:
      typeof monthlyContent === 'string'
        ? getText(monthlyContent)
        : monthlyContent,
    yearly:
      typeof yearlyContent === 'string' ? getText(yearlyContent) : yearlyContent
  });

  const tableRows = [
    createTableRow(
      'plans_selector.yearly.modals.compare_table.savings_vs_traditional',
      'plans_selector.yearly.modals.compare_table.up_to_off',
      'plans_selector.yearly.modals.compare_table.additional_up_to_off'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.page_usage',
      'plans_selector.yearly.modals.compare_table.pages_monthly',
      'plans_selector.yearly.modals.compare_table.pages_yearly'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.renewal',
      'plans_selector.yearly.modals.monthly_tab',
      'plans_selector.yearly.modals.compare_table.yearly_column'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.cancel',
      'plans_selector.yearly.modals.compare_table.anytime',
      'plans_selector.yearly.modals.compare_table.anytime'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.refund_policy',
      'plans_selector.yearly.modals.compare_table.not_applicable',
      'plans_selector.yearly.modals.compare_table.within_days'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.printing_over_plan',
      'plans_selector.yearly.modals.compare_table.for_pages',
      'plans_selector.yearly.modals.compare_table.auto_renew'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.paper_delivery',
      'plans_selector.yearly.modals.compare_table.add_on_available',
      'plans_selector.yearly.modals.compare_table.add_on_not_available'
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.smart_ink_level',
      <IconCheckmarkCircle filled />,
      <IconCheckmarkCircle filled />
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.ink_delivered',
      <IconCheckmarkCircle filled />,
      <IconCheckmarkCircle filled />
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.original_hp_ink',
      <IconCheckmarkCircle filled />,
      <IconCheckmarkCircle filled />
    ),
    createTableRow(
      'plans_selector.yearly.modals.compare_table.delivery_recycling',
      <IconCheckmarkCircle filled />,
      <IconCheckmarkCircle filled />
    )
  ];

  return (
    <>
      <HeaderContainer>
        {!isTablet && <span />}
        <PlanLabel isMonthly>
          <LabelText>
            {getText('plans_selector.yearly.modals.monthly_tab')}
          </LabelText>
        </PlanLabel>
        <PlanLabel>
          <LabelText>
            {getText('plans_selector.yearly.modals.yearly_tab')}
          </LabelText>
        </PlanLabel>
      </HeaderContainer>
      <Table>
        {tableRows.map((row, index) => (
          <TableRow key={row.title}>
            <TitleCell>{row.title}</TitleCell>
            <MonthlyCell>{row.monthly}</MonthlyCell>
            <YearlyCell>{row.yearly}</YearlyCell>
            {index !== tableRows.length - 1 && isTablet && <Divider />}
          </TableRow>
        ))}
      </Table>
    </>
  );
};
