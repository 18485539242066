import { Plan } from './plan';

export enum PlanSelectorActionType {
  FETCH_PLANS = 'FETCH_PLANS',
  FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS',
  FETCH_PLANS_FAIL = 'FETCH_PLANS_FAIL',
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER',
  SET_CONTAINTER_SIZE = 'SET_CONTAINTER_SIZE',
  SET_SELECTED_PLAN_GUID = 'SET_SELECTED_PLAN_GUID',
  SAVE_PLAN = 'SAVE_PLAN',
  SAVE_PLAN_SUCCESS = 'SAVE_PLAN_SUCCESS',
  SAVE_PLAN_FAIL = 'SAVE_PLAN_FAIL',
  CLEAR_SAVE_PLAN_ERROR = 'CLEAR_SAVE_PLAN_ERROR',
  SET_SELECTED_PLAN = 'SET_SELECTED_PLAN',
  SET_DEFAULT_PLAN_GUID_INK = 'SET_DEFAULT_PLAN_GUID_INK',
  SET_DEFAULT_PLAN_GUID_TONER = 'SET_DEFAULT_PLAN_GUID_TONER',
  SET_SELECTED_PROGRAM = 'SET_SELECTED_PROGRAM',
  SET_SUBSCRIPTION_STATE = 'SET_SUBSCRIPTION_STATE',
  SET_PAUSED_PLAN_GUID_STATE = 'SET_PAUSED_PLAN_GUID_STATE',
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_YEARLY_PLAN_SELECTED = 'SET_YEARLY_PLAN_SELECTED'
}

export type PlanSelectorAction = {
  type: PlanSelectorActionType;
  containerSize?: string;
  language?: string;
  country?: string;
  selectedPlanGUID?: string;
  plans?: Array<Plan>;
  error?: unknown;
  savedPlanGUID?: string;
  selectedPlan?: Plan;
  defaultPlanGUIDToner?: string;
  defaultPlanGUIDInk?: string;
  program?: string;
  showPlanProgramTabs?: boolean;
  showPriceIncreaseInfo?: boolean;
};
