import React, { useEffect, useRef, useState } from 'react';
import { usePlanSelectorState, useDispatch, useWindowSize } from '../../hooks';
import {
  MOBILE_MAX,
  TABLET_LANDSCAPE_MAX,
  TABLET_PORTRAIT_MAX,
  DESKTOP_CONTAINER,
  MOBILE_CONTAINER,
  MOBILE_CONTAINER_NARROW,
  TABLET_LANDSCAPE_CONTAINER,
  TABLET_PORTRAIT_CONTAINER,
  PLAN_PROGRAMMES,
  ANALITYCS_EVENT_LABEL
} from '../../../../common/constants';
import { setContainerSizeAction, setProgramAction } from '../../actions';
import SelectPlanButton from '../SelectPlanButton';
import { PlanContainer } from './styles';
import { PlanTitle } from './PlanTitle';
import { PlanLabels } from './PlanLabels';
import { PlanWarnings } from './PlanWarnings';
import { PlanProgramSelector } from './PlanProgramSelector';

export const PlanSelectorContent = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const planSelectorRef = useRef(null);
  const dispatch = useDispatch();
  const {
    savePlanError,
    containerSize,
    program,
    showPlanProgramTabs,
    trackClickEvent
  } = usePlanSelectorState();
  const shouldShowTabs =
    showPlanProgramTabs &&
    (program === PLAN_PROGRAMMES.INSTANT_INK ||
      program === PLAN_PROGRAMMES.INSTANT_TONER);
  const MIN_MOBILE_WIDTH = 380;

  useEffect(() => {
    recalculateContainerSize();
    window.addEventListener('resize', recalculateContainerSize);

    return () => {
      window.removeEventListener('resize', recalculateContainerSize);
    };
  }, []);

  const resolution = useWindowSize();

  useEffect(() => {
    setIsDesktop(resolution >= TABLET_LANDSCAPE_MAX);
  }, [resolution]);

  const recalculateContainerSize = async () => {
    const element = planSelectorRef.current;
    let newContainerSize: string;
    if (element.clientWidth <= MIN_MOBILE_WIDTH) {
      newContainerSize = MOBILE_CONTAINER_NARROW;
    } else if (element.clientWidth <= MOBILE_MAX) {
      newContainerSize = MOBILE_CONTAINER;
    } else if (element.clientWidth <= TABLET_PORTRAIT_MAX) {
      newContainerSize = TABLET_PORTRAIT_CONTAINER;
    } else if (element.clientWidth <= TABLET_LANDSCAPE_MAX) {
      newContainerSize = TABLET_LANDSCAPE_CONTAINER;
    } else {
      newContainerSize = DESKTOP_CONTAINER;
    }

    await dispatch(setContainerSizeAction(newContainerSize));
  };

  const handleChangeProgram = (programId) => {
    trackClickEvent(ANALITYCS_EVENT_LABEL, programId);
    dispatch(setProgramAction(programId));
  };
  return (
    <div ref={planSelectorRef}>
      <PlanContainer
        isDesktop={isDesktop}
        isMobileNarrow={containerSize === MOBILE_CONTAINER_NARROW}
      >
        <PlanTitle />
        <PlanProgramSelector
          handleChangeProgram={handleChangeProgram}
          shouldShowTabs={shouldShowTabs}
        />
        <PlanLabels />
        {savePlanError && <PlanWarnings />}
        <SelectPlanButton />
      </PlanContainer>
    </div>
  );
};
