import React from 'react';
import { AssetsProvider } from '@jarvis/web-assets-provider';
import { Container } from './styles';

interface PriceUpdateNoticeProps {
  getText: AssetsProvider['getText'];
  isPlansV1?: boolean;
}

const renderSupportContactLink = (getText) => {
  const contactSupportText = getText(
    'plans_selector.price_increase.contact_support'
  );

  let lastIndex = 0;
  let contactSupportWithoutLastCharacter = '';

  if (contactSupportText) {
    lastIndex = contactSupportText.length - 1;
    contactSupportWithoutLastCharacter = contactSupportText.slice(0, lastIndex);
  }

  return (
    <>
      <a
        data-testid="plans-price-increase-contact-support-link"
        data-linkid="ii_plans"
        data-analyticsid="ii_plans"
        href={getText('plans_selector.price_increase.contact_support_link')}
        target="_blank"
        rel="noreferrer"
      >
        {contactSupportWithoutLastCharacter}
      </a>
      {contactSupportText[lastIndex] || ''}
    </>
  );
};

export const PriceUpdateNotice: React.FC<PriceUpdateNoticeProps> = ({
  getText,
  isPlansV1
}) => {
  return (
    <Container
      isPlansV1={isPlansV1}
      data-testid="plans-price-increase-info"
      data-linkid="ii_plans"
      data-analyticsid="ii_plans"
    >
      <span>
        {getText('plans_selector.price_increase.prices_updated_effective')}
      </span>
      {renderSupportContactLink(getText)}
    </Container>
  );
};
