import primitives from '@veneer/primitives';
const { breakpoint0, breakpoint2, breakpoint4, breakpoint6 } =
  primitives.layout;

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_COUNTRY = 'US';
export const DEFAULT_COLOR_BLUE_UCDE = 'colorBlue';
export const DEFAULT_COLOR_BLUE_SMB = 'colorHpBlue';
export const DEFAULT_COLOR_PINK = 'colorPink';
export const DEFAULT_COLOR_GRAY = 'colorGray';
export const COLORS_LIMIT_II = 6;
export const COLORS_LIMIT_SMB = 5;
export const HIGHEST_COLOR_INDEX = 10;
export const COLOR_STARTING_INDEX_II = 4;
export const COLOR_STARTING_INDEX_SMB = 5;
export const DESKTOP_MAX = breakpoint6;
export const TABLET_LANDSCAPE_MAX = breakpoint4;
export const TABLET_PORTRAIT_MAX = breakpoint2;
export const MOBILE_MAX = breakpoint0;
export const SLIDES_TO_SHOW_SMB = 4;
export const SLIDES_TO_SHOW_II = 3;
export const DEFAULT_SLIDE_INDEX = 0;
export const PLAN_PROGRAMMES = {
  INSTANT_INK: 'i_ink',
  INSTANT_INK_PAPER: 'i_ink_paper',
  INSTANT_TONER: 'i_toner',
  SMB: 'i_smb_toner_color',
  SMB_MONO: 'i_smb_toner',
  PAPER: 'i_paper'
};
export const MOBILE_CONTAINER = 'mobile-container';
export const MOBILE_CONTAINER_NARROW = 'mobile-container-narrow';
export const TABLET_PORTRAIT_CONTAINER = 'tablet-portrait-container';
export const TABLET_LANDSCAPE_CONTAINER = 'tablet-landscape-container';
export const DESKTOP_CONTAINER = 'desktop-container';
export const ANALITYCS_EVENT_LABEL = 'ii_plans';
export const SUBSCRIBED_NO_PENS = 'subscribed-no-pens';
export const INITIATED_UNSUBSCRIBE = 'initiated-unsubscribe';
export const NON_BREAKING_SPACE = '\u00A0';
export const COMPARE_YEARLY_MONTHLY_STEP = 'compare_yearly_monthly_step';
export const YEARLY_PLAN_SELECTOR_STEP = 'yearly_plan_selector_step';
export const ARN_STEP = 'arn_step';
