import styled, { css } from 'styled-components';
import primitives from '@veneer/primitives';
import YearlyCardImage from '../../../../imgs/yearly-plans-card-banner.png';
import YearlyCardMobileImage from '../../../../imgs/yearly-plans-card-banner-mobile.png';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import Modal from '@veneer/core/dist/scripts/modal';
import {
  ARN_STEP,
  COMPARE_YEARLY_MONTHLY_STEP,
  YEARLY_PLAN_SELECTOR_STEP
} from '../../../common/constants';

export const YearlyCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 340px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  cursor: pointer;

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    width: 100%;
    margin-right: 0;
    justify-self: center;
    min-height: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  background-color: #e6e6e6;
  border-radius: 8px 8px 0px 0px;
`;

export const YearlyImage = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  background-position: center;
  background-size: cover;
  background-image: url(${YearlyCardImage});
  border-radius: 8px 8px 0px 0px;

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    background-image: url(${YearlyCardMobileImage});
    margin-right: 17px;
  }
`;

export const NewTag = styled.div`
  && {
    width: fit-content;
    margin: 16px 0 8px 16px;
    background-color: ${primitives.color.cornFlowerBlue7};
    border-radius: 8px;
    color: ${primitives.color.white};
  }
`;

export const NewTagContent = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 8px;
  }
`;

export const IconPriceTagContainer = styled.div`
  && {
    display: flex;
    margin-right: 2px;
  }
`;

export const TitleContainer = styled.div`
  &&& {
    h1 {
      font-size: 20px;
      font-weight: 400;
      color: ${primitives.color.gray12};
      line-height: 28px;
      font-family: ${primitives.typography.family0};
      margin: 0 16px;
      word-break: normal;
    }
  }
`;

export const Description = styled.span`
  && {
    font-size: 12px;
    font-weight: 400;
    color: ${primitives.color.gray12};
    line-height: 16px;
    font-family: ${primitives.typography.family0};
    margin: 8px 16px 0 16px;
    word-break: normal;

    sup {
      font-size: 8px;
      line-height: 0;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 16px 16px 40px 16px;

  button {
    width: 100%;
    height: 42px;
    border-radius: 8px;

    span {
      white-space: normal;
    }
  }

  @media (max-width: ${breakpointsHelper.extra_small_575px}) {
    margin-top: 0;
    padding-bottom: 24px;
  }
`;

interface YearlyModalProps {
  $currentStep: string;
}

export const YearlyModal = styled(Modal)<YearlyModalProps>`
  ${({ $currentStep }) => {
    if ($currentStep === COMPARE_YEARLY_MONTHLY_STEP) {
      return css`
        .vn-modal--content {
          max-width: 920px;
          padding: 48px 40px 40px 40px;

          @media (max-width: ${breakpointsHelper.extra_small_575px}) {
            padding: 40px 16px 40px 16px;
          }
        }
      `;
    } else if ($currentStep === YEARLY_PLAN_SELECTOR_STEP) {
      return css`
        .vn-modal--content {
          max-width: 920px;
          padding: 48px 40px 40px 40px;

          @media (max-width: ${breakpointsHelper.medium_min_768px}) {
            padding: 40px 48px 48px 48px;
          }

          @media (max-width: ${breakpointsHelper.extra_small_575px}) {
            padding: 40px 16px 40px 16px;
          }
        }
      `;
    } else if ($currentStep === ARN_STEP) {
      return css`
        .vn-modal--content {
          padding: 0;
          max-width: 648px;
        }

        @media (min-width: ${breakpointsHelper.large_min_992px}) {
          .vn-modal__close {
            background-color: #404040;
            opacity: 50%;
            border-radius: 50%;
            svg {
              color: #ffffff;
            }
          }
        }
      `;
    }
  }}
`;
