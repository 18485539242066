import React from 'react';
import {
  LargeFont,
  PriceStrike,
  YearlyPlanCard,
  YearlyPlanContainer,
  CardContent,
  SaveText,
  PlaceHolder,
  SmallFont,
  CardText,
  SelectPlanButton,
  ButtonArea,
  SelectPlanButtonText
} from './styles';
import { PlanTag } from '../../../YearlyPlanSelector/components/PlanTag';
import { PlanName } from '../PlanName';
import { useGetText } from '../../../common/hooks/useGetText';
import { useDispatch } from '../../../common/hooks/useDispatch';
import { setCurrentStepAction } from '../../../common/actions/setCurrentStepAction';
import { ARN_STEP } from '../../../common/constants';

interface YearlyPlansCardProps {
  pagesPerYear: string;
  pricePerYear: string;
  savedPricePerYear: string;
  numberOfCircles?: number;
  tagColor?: string;
  hasTag?: boolean;
  dataTestId?: string;
}

export const YearlyPlansCard: React.FC<YearlyPlansCardProps> = ({
  pagesPerYear,
  savedPricePerYear,
  pricePerYear,
  numberOfCircles,
  dataTestId,
  tagColor = '#E6E6E6',
  hasTag = false
}) => {
  const getText = useGetText();
  const jarvisDispatch = useDispatch();

  const handleSelectPlanClick = () => {
    if (jarvisDispatch) {
      jarvisDispatch(setCurrentStepAction(ARN_STEP));
    }
  };

  return (
    <>
      <YearlyPlanCard data-testid={dataTestId}>
        {hasTag ? (
          <PlanTag tagTitle="Your Plan as yearly" color={tagColor} />
        ) : (
          <PlaceHolder />
        )}
        <YearlyPlanContainer>
          <PlanName
            planName={'moderate'}
            circlesNumber={numberOfCircles}
            blueColor={true}
          />
          <CardContent>
            <CardText>
              <p>
                <LargeFont>{pagesPerYear}</LargeFont>{' '}
                {getText('plans_selector.plan_card.pages_per_year')}
              </p>
              <p>
                <PriceStrike>{pricePerYear}</PriceStrike>
                <SmallFont>
                  {' '}
                  {getText('plans_selector.plan_card.per_year')}
                </SmallFont>
              </p>
              <p>{savedPricePerYear}</p>
              <SaveText>
                {getText(
                  'plans_selector.yearly.modals.all_yearly_plans.save_per_year',
                  { amount: '$16.99' }
                )}
              </SaveText>
            </CardText>
            <ButtonArea>
              <SelectPlanButton
                onClick={handleSelectPlanClick}
                appearance="secondary"
              >
                <SelectPlanButtonText>
                  {getText('plans_selector.plan_modal.select_plan')}
                </SelectPlanButtonText>
              </SelectPlanButton>
            </ButtonArea>
          </CardContent>
        </YearlyPlanContainer>
      </YearlyPlanCard>
    </>
  );
};
