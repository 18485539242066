import React from 'react';
import { CompareYearlyAndMonthlyPlan } from '../../components/CompareYearlyAndMonthlyPlan';
import {
  ARN_STEP,
  COMPARE_YEARLY_MONTHLY_STEP,
  YEARLY_PLAN_SELECTOR_STEP
} from '../../../common/constants';
import { ConfirmYearlyChange } from '../../components/ConfirmYearlyChange';
import { useJarvisPlansState } from '../../../common/hooks/useJarvisPlansState';
import { AllYearlyPlansSelector } from '../AllYearlyPlansSelector';
import { BillingCvvProps } from '../ConfirmYearlyChange/components/BillingCvv';

type YearlyPlanSelectorContentProps = BillingCvvProps;

export const YearlyPlanSelectorContent: React.FC<
  YearlyPlanSelectorContentProps
> = ({ creditCard, onChangePaymentMethod, onSubmitCVV, cvvStatus }) => {
  const { currentStep } = useJarvisPlansState();

  switch (currentStep) {
    case COMPARE_YEARLY_MONTHLY_STEP:
      return <CompareYearlyAndMonthlyPlan />;
    case ARN_STEP:
      return (
        <ConfirmYearlyChange
          creditCard={creditCard}
          onChangePaymentMethod={onChangePaymentMethod}
          onSubmitCVV={onSubmitCVV}
          cvvStatus={cvvStatus}
        />
      );
    case YEARLY_PLAN_SELECTOR_STEP:
      return <AllYearlyPlansSelector />;
    default:
      return <CompareYearlyAndMonthlyPlan />;
  }
};
